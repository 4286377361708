<template>
  <div class="header">
    <h1>{{ this.$t("mypage.coupon") }}</h1>
    <ul class="path">
      <li>
        <router-link to="/mypage">{{ this.$t("mypage.mypage") }}</router-link>
      </li>
      <li class="current">
        <router-link to="/mypage/coupon">{{
          this.$t("mypage.coupon")
        }}</router-link>
      </li>
    </ul>
  </div>
  <div class="body">
    <div class="search_wrap">
      <div class="status">
        <div
          class="type"
          :class="{ selected: this.search_status == 'UNUSED' }"
          @click="this.click_search_status('UNUSED')"
        >
          <h3>{{ this.$t("mypage.unused") }}</h3>
          <img src="@/assets/img/icon_coupon_available.svg" alt="Unused" />
        </div>

        <div
          class="type"
          :class="{ selected: this.search_status == 'USED' }"
          @click="this.click_search_status('USED')"
        >
          <h3>{{ this.$t("mypage.used") }}</h3>
          <img src="@/assets/img/icon_coupon_used.svg" alt="Used" />
        </div>

        <div
          class="type"
          :class="{ selected: this.search_status == 'EXPIRED' }"
          @click="this.click_search_status('EXPIRED')"
        >
          <h3>{{ this.$t("mypage.expired") }}</h3>
          <img src="@/assets/img/icon_expired.svg" alt="Expired" />
        </div>
      </div>
    </div>

    <ul class="coupon_list">
      <li class="empty" v-if="this.coupon_data.length <= 0">
        {{ this.$t("common.no_data") }}
      </li>
      <li
        v-for="(value, index) in this.coupon_data"
        :key="index"
        :class="{ disabled: value.coupon_map_status != 'UNUSED' }"
      >
        <div class="top">
          <div class="name">{{ value.coupon_name }}</div>
          <div class="discount">
            <div class="amount">
              {{
                value.coupon_type == "PRICE"
                  ? this.common.number_format(value.coupon_amount)
                  : this.common.number_format(value.coupon_amount * 100.0, 1)
              }}
            </div>
            <div class="unit">
              {{ value.coupon_type == "PRICE" ? value.unit : "%" }}
            </div>
            <div
              class="limit"
              v-if="value.coupon_limit > 0 && value.coupon_type == 'PERCENT'"
            >
              {{ this.$t("order.max") }}
              {{ this.common.currency_format(value.coupon_limit, value.unit) }}
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="date">
            {{ this.$t("order.issue_date") }}:
            {{
              this.common.date_short_format(
                this.common.date_parse(value.issue_date),
                this.storage.get_user_country()
              )
            }}
          </div>
          <div class="date">
            {{ this.$t("order.expire_date") }}:
            {{
              this.common.date_short_format(
                this.common.date_parse(value.expire_date)
              )
            }}
          </div>
          <div class="date">
            {{ this.$t("order.use_date") }}:
            {{
              this.common.date_short_format(
                this.common.date_parse(value.use_date)
              )
            }}
          </div>
          <div class="status">
            {{ this.convert_coupon_map_status_text(value.coupon_map_status) }}
          </div>
        </div>
      </li>
    </ul>

    <button
      class="button outline small sharp compact more"
      v-if="this.coupon_data.length && !this.data_fully_loaded"
      @click="load_coupon_data"
    >
      {{ this.$t("common.load_more") }}
    </button>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "MyCoupon",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.mycoupon"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.mycoupon"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      data_fully_loaded: false,
      search_status: "",
      search_page: 1,
      coupon_data: [],
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.load_coupon_data();
  },
  methods: {
    // 쿠폰 정보 가져오기
    load_coupon_data: function () {
      if (!this.storage.is_logged_in()) return;

      // 데이터가 이미 다 로드되었으면 더이상 진행안함.
      if (this.data_fully_loaded) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/coupon/list",
          {
            user_id: self.storage.get_user_id(),
            coupon_map_status: self.search_status,
            page: self.search_page,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;

          // 처음 로드할 경우 데이터 초기화
          if (self.search_page <= 1) {
            self.coupon_data = [];
          }

          for (let i = 0; i < data.length; i++) {
            self.coupon_data.push(data[i]);
          }

          if (data.length <= 0) {
            self.data_fully_loaded = true;
          } else {
            self.search_page++;
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_coupon_data

    click_search_status: function (type) {
      if (this.search_status == type) {
        this.search_status = "";
      } else {
        this.search_status = type;
      }

      this.search_page = 1;
      this.data_fully_loaded = false;

      this.load_coupon_data();
    }, //-- click_search_status

    convert_coupon_map_status_text: function (status) {
      if (status.trim().toUpperCase() == "UNUSED") {
        return this.$t("mypage.unused");
      } else if (status.trim().toUpperCase() == "USED") {
        return this.$t("mypage.used");
      } else if (status.trim().toUpperCase() == "EXPIRED") {
        return this.$t("mypage.expired");
      }

      return "";
    }, //-- convert_coupon_map_status_text
  },
};
</script>

<style scoped>
.body {
}
.body > .search_wrap {
  padding: 1.2rem 1.6rem;
  margin: 0 0 1rem 0;
  border-radius: 0.8rem;
  background: #f4f4f4;
}
.body > .search_wrap > .status {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
}
.body > .search_wrap > .status > .type {
  width: 33.33%;
  padding: 0.8rem;
  border-radius: 0.8rem;
  cursor: pointer;
  user-select: none;
}
.body > .search_wrap > .status > .type.selected {
  background: #dedede;
}

@media (hover: hover) and (pointer: fine) {
  .body > .search_wrap > .status > .type:hover {
    background: #dedede;
  }
}

.body > .search_wrap > .status > .type > h3 {
  font-size: 1.1rem;
  font-weight: 400;

  padding: 0;
  margin: 0 0 0.8rem 0;
}
.body > .search_wrap > .status > .type > img {
  height: 2.6rem;
}

.body > ul.coupon_list {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.body > ul.coupon_list > li {
  display: block;
  width: calc((100% - 1rem) / 2);
  border: 1px solid #e74356;
  margin: 0 0 0.8rem 0;
  margin-right: 1rem;

  user-select: none;
}
.body > ul.coupon_list > li:nth-child(2n) {
  margin-right: 0;
}
.body > ul.coupon_list > li.empty {
  width: 100%;
  border: none;
  text-align: center;
  padding: 3rem 0;
  margin: 0;
}
.body > ul.coupon_list > li.disabled {
  opacity: 0.45;
}
.body > ul.coupon_list > li > .top,
.body > ul.coupon_list > li > .bottom {
  position: relative;
  padding: 0.8rem;
}
.body > ul.coupon_list > li > .top {
  background: url("../../assets/img/logo_600.png") no-repeat;
  background-position: center right 1rem;
  background-size: 2.4rem auto;
}
.body > ul.coupon_list > li > .bottom {
  position: relative;
  border-top: 1px solid #ccc;
  background: #f4f4f4;
}
.body > ul.coupon_list > li > .top > .name {
  font-weight: 700;
  margin: 0 0 0.2rem 0;
}
.body > ul.coupon_list > li > .top > .discount {
  display: block;
  color: #e74356;
  font-weight: 700;
  font-size: 1.4rem;
}
.body > ul.coupon_list > li > .top > .discount > .amount {
  display: inline-block;
}
.body > ul.coupon_list > li > .top > .discount > .unit {
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
}
.body > ul.coupon_list > li > .top > .discount > .limit {
  display: inline-block;
  color: #808080;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0 0 0 0.8rem;
}
.body > ul.coupon_list > li > .bottom > .date {
  font-size: 0.875rem;
  color: #808080;
  margin: 0 0 0.2rem 0;
}
.body > ul.coupon_list > li > .bottom > .date:last-child {
  margin: 0;
}
.body > ul.coupon_list > li > .bottom > .status {
  position: absolute;
  right: 1rem;
  bottom: 50%;
  transform: translate(0, 50%);

  font-size: 0.76rem;
  font-weight: 300;
  padding: 0.3rem 0.8rem;
  color: #fff;
  background: #333;
  border-radius: 3rem;
}

.body > button.more {
  display: block;
  margin: 2rem auto 0 auto;
}

@media (max-width: 600px) {
  .body > ul.coupon_list > li {
    width: 100%;
    margin: 0 0 0.8rem 0;
  }
}
</style>
